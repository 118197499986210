import data from "../data.json";
//https://pixabay.com/photos/businessman-consulting-business-2606506/
import logo from "../images/events.jpg";
import GenericTable from "../components/GenericTable";
import GenericHeader from "../components/GenericHeader";
import { format, isAfter } from "date-fns";
import _ from "lodash";
import {PRIMARY, SECONDARY} from "../components/Colors";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function Events({setDivHeight}) {
    const history = useLocation()
    useEffect(()=>{
        document.querySelector('#some-container').scrollIntoView()
    }, [history])
  const events = data.events;
  const upcoming = events.filter(
    (e) => !isAfter(new Date(), new Date(`${e.date} ${e.time}`))
  );
  let gupcoming = _.sortBy(upcoming, (e) => e.time)
    gupcoming = _.sortBy(gupcoming, (e) => e.date);

  gupcoming = _.groupBy(gupcoming, (e) => e.date);
    const past = events.filter((e) =>
    isAfter(new Date(), new Date(`${e.date} ${e.time}`))
  );
  let gpast = _.sortBy(past, (e) => e.time);
  gpast = _.groupBy(gpast, (e) => e.date);
  return (
    <div style={{ flex: 1 }}>
      <GenericHeader title={"Events"} setDivHeight={setDivHeight}/>
      <div id={"some-container"}>
        <div style={{backgroundColor: PRIMARY, height: 100, justifyContent:'center', alignItems:'center', display:'flex'}}>
            <h3 className="headers" style={{color: SECONDARY, textAlign:'center'}}>IEEE Region 8 Robot Championship Malta 2022</h3>
        </div>
      {/*<img src={logo} alt="Logo" width={"100%"} height={100} />*/}
      <div className="container">
          <p>Open from 10:00 till 19:00 STEM activities with robots and more. </p>
          <p>{"Some activities require registration.\nFor more information email robotchampionship@ieeemalta.com"}
          </p>
        <h3 className="headers">Upcoming Events</h3>
        {Object.keys(gupcoming).map((u) => {
          let data = [];
          gupcoming[u].map((d) => {
            data.push({
              Time: d.time,
              Location: d.location,
              Description: d.details,
            });
          });
          return (
            <div>
              <h3 className="headers" style={{ fontSize: 18 }}>
                {format(new Date(u), "EEEE MMMM d, yyyy")}
              </h3>
              <GenericTable
                headers={["Time",  "Description"]}
                data={data}
              />
            </div>
          );
        })}
        {data.areaMap && (
          <img
            src={require(`../images/${data.areaMap}`)}
            alt="Logo"
            width={"100%"}
          />
        )}
        <h3 className="headers">Past Events</h3>
        {Object.keys(gpast).map((u) => {
          let data = [];
          gpast[u].map((d) => {
            console.log(d);
            data.push({
              Time: d.time,
              Location: d.location,
              Description: d.details,
            });
          });
          return (
            <div>
              <h3 className="headers" style={{ fontSize: 18 }}>
                {format(new Date(u), "EEEE MMMM d, yyyy")}
              </h3>
              <GenericTable
                headers={["Time", "Location", "Description"]}
                data={data}
              />
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
}
