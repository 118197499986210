import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SECONDARY, PRIMARY } from "../components/Colors";
import GenericHeader from "../components/GenericHeader";
import { Person } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GenericTable from "../components/GenericTable";
import React, {useEffect} from "react";

export default function SpecificGame({ width, setDivHeight }) {
  let location = useLocation();
  const navigate = useNavigate();
  const team = location.state.game;
  const path = team.photoPath;
  let height = 300;
  if (width < 400) {
    height = 100;
  }
  const history = useLocation()
  useEffect(()=>{
    document.querySelector('#some-container').scrollIntoView()
  }, [history])
  return (
    <div>
      <GenericHeader
        setDivHeight={setDivHeight}
        title={team.name}
        backAction={() => navigate("/games")}
      />
      <div className={"container"} id={"some-container"} >
        {/*<div style={{backgroundColor: PRIMARY}}>*/}
        {/*    <img*/}
        {/*        src={require(`../images/${path}`)}*/}
        {/*        alt="Team image"*/}
        {/*        height={height}*/}

        {/*        style={{ alignSelf: "center", maxWidth: width, maxHeight: 200 }}*/}
        {/*    />        </div>*/}
        <h3 className={"headers"}>Objectives</h3>
        <div style={{ paddingLeft: 10 }}>
          <p>{team.objective}</p>
        </div>
        {team.points &&
            <div>
          <h3 className={"headers"}>Points</h3>
          <div style={{paddingLeft: 10}}>
          <p>{team.points}</p>
            {team.table &&
                <GenericTable headers={["weight", "points"]}
                data={[{"weight":0.5, "points":5},
                  {"weight":1.0, "points":10},
                  {"weight":2.0, "points":15},
                  {"weight":5.0, "points":20},
                  {"weight":5.0, "points":20},
                  {"weight":10.0, "points":30},
                  {"weight":"If player’s opponent falls into opponent’s pit", "points":50},
                  {"weight":"If player’s opponent falls into player’s pit", "points":100}
                  ]}/>
            }
          </div>
            </div>
        }
        <h3 className={"headers"}>Rules</h3>
        <div style={{ paddingLeft: 10 }}>
          <p>{team.rules}</p>
          {team.page && <p>For rules click <a href={"https://www.dropbox.com/s/tw6zscqijcqgc3e/Robot%20Championship%202022%20v1.1.pdf?dl=0"}>here</a></p>}
        </div>

        <p>{team.others}</p>
      </div>
    </div>
  );
}
