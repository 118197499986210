import data from "../data.json";
import logo from "../images/dashboard.jpg";
import GenericTable from "../components/GenericTable";
import GenericHeader from "../components/GenericHeader";
import { PRIMARY, SECONDARY } from "../components/Colors";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScoresTable from "../components/Tables";
import { getDatabase, onValue, ref } from "firebase/database";
import ScoresTableVs from "../components/Tables copy";
export default function Scores({ setDivHeight }) {
  const navigate = useNavigate();
  const history = useLocation();
  useEffect(() => {
    document.querySelector("#some-container").scrollIntoView();
  }, [history]);

  return (
    <div style={{ flex: 1 }}>
      <GenericHeader title={"Games"} setDivHeight={setDivHeight} />
      <div id={"some-container"}>
        <div
          style={{
            backgroundColor: PRIMARY,
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <h3
            className="headers"
            style={{ color: SECONDARY, textAlign: "center" }}
          >
            IEEE Region 8 Robot Championship Malta 2022
          </h3>
        </div>
        {/*<img src={logo} alt="Logo" width={"100%"} height={100} />*/}
        <div className="container">
          <p>
            The 4 games and Rules and regulations can be accessed from{" "}
            <a
              href={
                "https://www.dropbox.com/s/tw6zscqijcqgc3e/Robot%20Championship%202022%20v1.1.pdf?dl=0"
              }
            >
              here
            </a>
          </p>
          <ScoresTable
            headings={["id", "Hanging Hazard2", "F Points"]}
            header={"Hanging Hazard Final"}
            title={"Hanging Hazard2"}
          />
          <ScoresTable
            headings={["id", "Hanging Hazard", "RU Points"]}
            header={"Hanging Hazard Runner Up"}
            title={"Hanging Hazard"}
          />
          <ScoresTableVs
            headings={["Death Clock2", "SF Points"]}
            header={"Death Clock Round 2"}
            title={"Death Clock2"}
          />
          <ScoresTableVs
            headings={["Death Clock", "QF Points"]}
            header={"Death Clock"}
            title={"Death Clock"}
          />
          <ScoresTable
            headings={["id", "Weight Game", "R2 Points"]}
            header={"Weight Game"}
            title={"Weight Game"}
          />
          <ScoresTable
            headings={["id", "Time To Fall", "R1 Points"]}
            header={"Time To Fall"}
            title={"Time To Fall"}
          />
          {/*<GenericTable*/}
          {/*  headers={["Place", "Team", "Points"]}*/}
          {/*  //   data={[{ Place: "1st", Team: "team1", Points: 12 }]}*/}
          {/*/>*/}
          {Object.keys(data.scores).map((d, i) => {
            const cont = data.scores[d];
            return (
              <div>
                <h3 className="headers">{cont.title}</h3>
                <p>
                  {cont.content}
                  {cont.link && <a href={cont.link}>here</a>}
                </p>
                {cont.photoPath && (
                  <img
                    src={require(`../images/${cont.photoPath}`)}
                    alt="location photo"
                  />
                )}
              </div>
            );
          })}
          <h3 className="headers">{"The 4 Games"}</h3>

          {Object.keys(data.games).map((d, i) => {
            const cont = data.games[d];
            return (
              <div
                style={{
                  backgroundColor: i % 2 === 0 ? SECONDARY : "white",
                  display: "flex",
                }}
                onClick={() => {
                  navigate("/game", { state: { game: cont } });
                }}
              >
                <div
                  style={{ paddingLeft: 10 }}
                  onClick={() => {
                    navigate("/game", { state: { game: cont } });
                  }}
                >
                  <h3 className="headers">{cont.name}</h3>
                  <p>
                    <b>Objective: </b>
                    {cont.objective}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
