import data from "../data.json";
import GenericHeader from "../components/GenericHeader";
import { PRIMARY, SECONDARY } from "../components/Colors";
import SpecificTeam from "./SpecificTeam";
import {useLocation, useNavigate} from "react-router-dom";
import {createRef, useEffect, useRef} from "react";

export default function Teams({ setDivHeight, width }) {
  const navigate = useNavigate();
    const history = useLocation()
    useEffect(()=>{
        document.querySelector('#some-container').scrollIntoView()
    }, [history])
  return (
    <div style={{ flex: 1 }}>
      <GenericHeader title={"Teams"} setDivHeight={setDivHeight} />
      <div id={"some-container"} className="" >
        {Object.keys(data.teamData).map((t, i) => {
          let team = data.teamData[t];
          let path = team.photoPath;
          console.log(path);
          return (
            <div
              style={{
                backgroundColor: i % 2 === 0 ? SECONDARY : "white",
                padding: 20,
                display: "flex",
              }}
              onClick={() => {
                navigate("/team", { state: { team: team } });
              }}
            >
              <img
                src={require(`../images/${path}`)}
                alt="Team image"
                width={width / 3}
                style={{ alignSelf: "center", backgroundColor: PRIMARY }}
                onClick={() => {
                  navigate("/team", { state: { team: team } });
                }}
              />
              <div
                style={{ paddingLeft: 10 }}
                onClick={() => {
                  navigate("/team", { state: { team: team } });
                }}
              >
                <h3 className="headers">{team.name}</h3>
                <p>{team.moto}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
