import data from "../data.json";
//https://pixabay.com/photos/macro-cogwheel-gear-engine-vintage-1452987/
import logo from "../images/about.jpg";
import GenericTable from "../components/GenericTable";
import GenericHeader from "../components/GenericHeader";
import {PRIMARY, SECONDARY} from "../components/Colors";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
export default function About({setDivHeight}) {
    const history = useLocation()
    useEffect(()=>{
        document.querySelector('#some-container').scrollIntoView()
    }, [history])
  return (
    <div style={{ flex: 1 }}>
      <GenericHeader title={"About"} setDivHeight={setDivHeight} />
      <div  id={"some-container"}>
        <div style={{backgroundColor: PRIMARY, height: 100, justifyContent:'center', alignItems:'center', display:'flex'}}>
            <h3 className="headers" style={{color: SECONDARY, textAlign:'center'}}>IEEE Region 8 Robot Championship Malta 2022</h3>
        </div>
      {/*<img src={logo} alt="Logo" width={"100%"} height={100} />*/}
      <div className="container">
         
        {Object.keys(data.aboutPage.others).map((d, i) => {
          const cont = data.aboutPage.others[d];
          return (
            <div>
              <h3 className="headers">{cont.title}</h3>
              <p>{cont.content}</p>
              {cont.photoPath && (
                <img
                  src={require(`../images/${cont.photoPath}`)}
                  alt="Logo"
                  height={100}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="container">
        <h3 className="headers">{data.aboutPage.location}</h3>
        {data.aboutPage.email && (
          <p>
            <b>Email: </b>
            {data.aboutPage.email}
          </p>
        )}
          {data.aboutPage.website && (
              <p>
                  <b>Website: </b>
                  {data.aboutPage.website}
              </p>
          )}
        {data.aboutPage.photoPath && (
          <img
            src={require(`../images/${data.aboutPage.photoPath}`)}
            alt="location photo"
            width={"100%"}
          />
        )}
      </div>
    </div></div>
  );
}
