import logo from "./logo.svg";
import "./App.css";
import GenericHeader from "./components/GenericHeader";
import Dashboard from "./screens/Dashboard";
import BottomNav from "./components/BottomNav";
import Sponsers from "./components/Sponsers";
import getWindowSize from "./components/getWindowSize";
import React, { useState, useEffect, useRef } from "react";
import Teams from "./screens/Teams";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes, useLocation,
} from "react-router-dom";
import SpecificTeam from "./screens/SpecificTeam";
import Scores from "./screens/Scores";
import About from "./screens/About";
import Events from "./screens/Events";
import SpecificGame from "./screens/SpecificGame";
function App() {
  const [value, setValue] = useState(2);
  console.log(value);

  const [height, setHeight] = useState(300);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [divHeight, setDivHeight] = useState(90);
  const ref = useRef()
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.scrollTo(0,0)

    };
  }, []);

  useEffect(() => {
    if (windowSize && windowSize.innerHeight)
      setHeight(windowSize.innerHeight - 50 - divHeight);
  }, [windowSize]);


  useEffect(() => {
    if (windowSize && windowSize.innerHeight)
      setHeight(windowSize.innerHeight - 50 - divHeight);
  }, [divHeight]);

  return (
    <div className="App">
      <div
        style={{
          height: height,
          overflowY: "scroll",
          top: divHeight,
          position: "relative",
        }}
        ref={ref}
      >
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard setDivHeight={setDivHeight}/>}></Route>
            <Route path="/teams" element={<Teams setDivHeight={setDivHeight} width={windowSize.innerWidth}/>} />
            <Route path={`/team`} element={<SpecificTeam width={windowSize.innerWidth} setDivHeight={setDivHeight}/>} />
            <Route path={"/games"} element={<Scores setDivHeight={setDivHeight}/>} />
            <Route path={"/about"} element={<About setDivHeight={setDivHeight}/>} />
            <Route path={"/events"} element={<Events setDivHeight={setDivHeight}/>} />
            <Route path={`/game`} element={<SpecificGame width={windowSize.innerWidth} setDivHeight={setDivHeight}/>} />

          </Routes>
          <Sponsers />
          <BottomNav value={value} setValue={setValue} />
        </Router>
      </div>
    </div>
  );
}

export default App;
