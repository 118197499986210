import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { PRIMARY } from "./Colors";
import {
  Home,
  Scoreboard,
  People,
  CalendarToday,
  Info,
} from "@mui/icons-material";
import Dashboard from "../screens/Dashboard";
import Teams from "../screens/Teams";
import SpecificTeam from "../screens/SpecificTeam";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function BottomNav({ value, setValue }) {
  const navigate = useNavigate();
  const location = useLocation()
    console.log(location)

    useEffect(()=>{
        if(location.pathname == "/games"){
            setValue(0)
        }else if(location.pathname == "/teams"){
            setValue(1)
        }else if(location.pathname == "/"){
            setValue(2)
        }else if(location.pathname == "/events"){
            setValue(3)
        }else if(location.pathname == "/about"){
            setValue(4)
        }
    }, [location])
  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        console.log(newValue);
        setValue(newValue);
        if (newValue == 0) navigate("/games");
        if (newValue == 1) navigate("/teams");
        if (newValue == 2) navigate("/");
        if (newValue == 3) navigate("/events");
        if (newValue == 4) navigate("/about");
      }}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "space-between",
      }}
      elevation={3}
    >
      <BottomNavigationAction
        label="Games"
        icon={<Scoreboard />}
        style={{
          backgroundColor: value != 0 ? "white" : PRIMARY,
          color: value == 0 ? "white" : PRIMARY,
          minWidth: 50,
        }}
      />
      <BottomNavigationAction
        label="Teams"
        icon={<People />}
        style={{
          backgroundColor: value != 1 ? "white" : PRIMARY,
          color: value == 1 ? "white" : PRIMARY,
          minWidth: 50,
        }}
      />
      <BottomNavigationAction
        label="Home"
        icon={<Home />}
        style={{
          backgroundColor: value != 2 ? "white" : PRIMARY,
          color: value == 2 ? "white" : PRIMARY,
          minWidth: 50,
        }}
      />
      <BottomNavigationAction
        label="Events"
        icon={<CalendarToday />}
        style={{
          backgroundColor: value != 3 ? "white" : PRIMARY,
          color: value == 3 ? "white" : PRIMARY,
          minWidth: 50,
        }}
      />
      <BottomNavigationAction
        label="About"
        icon={<Info />}
        style={{
          backgroundColor: value != 4 ? "white" : PRIMARY,
          color: value == 4 ? "white" : PRIMARY,
          minWidth: 50,
        }}
      />
    </BottomNavigation>
  );
}
